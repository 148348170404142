import {ChatEngine} from 'react-chat-engine'
import ChatFeed from './components/ChatFeed';
import LoginForm from './components/LoginForm'
import './App.css';

const App = () =>{

    if(!localStorage.getItem('username')) return <LoginForm></LoginForm>
    return(
        <ChatEngine 
        height='100vh'
        projectID="b552010d-898d-4178-8762-ea4ea4654d53"
        userName={localStorage.getItem('username')}
        userSecret={localStorage.getItem('password')}
        renderChatFeed={(chatAppProps)=><ChatFeed {...chatAppProps} />}
        />
    );
}

export default App;